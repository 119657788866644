import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BackgroundImage from '../components/backgroundImage';
import Footer from '../components/footer';

const NotFoundPage = () => (
  <Layout className="error">
    <SEO
      context={{
        title: 'Error: Niet gevonden',
        description: 'Pagina mist, probeer de homepage.',
      }}
    />
    <BackgroundImage />
    <section
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
      }}
    >
      <article>
        <h1>
          <span aria-label="alarm" role="img">
            🚨
          </span>{' '}
          Welkom bij Duuk{' '}
          <span aria-label="alarm" role="img">
            🚨
          </span>
        </h1>
        Mijn plek in cyberspace, waar helaas iets is misgegaan{' '}
        <span aria-label="alarm" role="img">
          ⛔
        </span>
        ️. Probeer opnieuw of check hieronder om mijn vader aan het werk te
        zetten!
      </article>
      <Footer />
    </section>
  </Layout>
);

export default NotFoundPage;
